import React, { useState, useEffect } from "react";
import "../Reports.scss";
import { IconControl, ToastNotifications } from "spark-core-dx/components";
import { ReportingIFrame } from "./ReportingIFrame";
import { useReportsDomainContext } from "../ReportsDomainProvider";

const ReportCard = ({}) => {
	const { reports, id } = useReportsDomainContext();
	const [report, setReport] = useState({});
	const url = `${window.location.origin}/Reports/${id}`;
	const [toasterMessage, setToasterMessage] = useState(null);

	useEffect(() => {
		setReport(reports?.find((x) => x._id === id));
	}, [reports]);

	const handleShare = (shareLink) => {
		navigator.clipboard.writeText(shareLink);
		setToasterMessage("Share link has been saved to your clipboard.");
		setTimeout(() => setToasterMessage(null), 6000);
	};

	return (
		<div className="report-card">
			{toasterMessage && <ToastNotifications position={"top"} message={toasterMessage} />}
			<div className="report">
				<div className="report__header">
					<div className="report__header-title">
						<h3>{report?.Name}</h3>
						{report?.Description && <div className="report__header-subtitle">{report?.Description}</div>}
					</div>
					<div className="report__header-button-container">
						<IconControl
							iconKey={"share"}
							title={"Share"}
							onClick={() => {
								handleShare(url);
							}}
						/>
					</div>
				</div>

				<div className="report__inner">
					<ReportingIFrame reportId={report?._id} />
				</div>
			</div>
		</div>
	);
};

export default ReportCard;
