import React, { useState, useEffect, useRef } from "react";
import { useAuthContext, useCoreContext, useCoreStateContext } from "spark-core-dx/contexts";
import { useCoreRoute, useCorePermissions, useGenericManager } from "spark-core-dx/hooks";
import "./landing.scss";
import { NotificationList, IconControl, LoadingSkeleton, SearchInput, TableCards, DialogControl } from "spark-core-dx/components";
import AppCard from "../components/AppCard";
import Apps from "../components/Apps";
import { ItemServiceFactory } from "../../../services/ItemServiceFactory";
import _ from "lodash";
import { ReportingIFrame } from "../../reports/screens/ReportingIFrame";

const Landing = () => {
	const itemService = new ItemServiceFactory();
	const { isAuthorized } = useAuthContext();
	const { coreNavigate } = useCoreRoute();
	const { canView } = useCorePermissions();
	const coreContext = useCoreContext();
	const coreState = useCoreStateContext();

	const profileMgr = useGenericManager(itemService.ItemServiceEnum.Profile);
	const notificationMgr = useGenericManager(itemService.ItemServiceEnum.Notification, itemService);
	const reportMgr = useGenericManager(itemService.ItemServiceEnum.Report, itemService);

	const carouselRef = useRef(null);
	const cardWidth = useRef(0);
	const visibleCards = useRef(0);
	const [isAtStart, setIsAtStart] = useState(true);
	const [isAtEnd, setIsAtEnd] = useState(false);

	const [openApps, setOpenApps] = useState(false);

	const appRef = useRef(null);
	const appListRef = useRef(null);
	const pageRef = useRef(null);
	const [divWidth, setDivWidth] = useState("75%");
	const visibleCountRef = useRef(0);
	const [notifHeight, setNotifHeight] = useState("600px");
	const [currentIndex, setCurrentIndex] = useState(0);

	const [reports, setReports] = useState([]);

	const [notifications, setNotifications] = useState([]);
	const [allNotifs, setAllNotifs] = useState([]);
	const [searchNotification, setSearchNotification] = useState("");

	const [visibleApps, setVisibleApps] = useState(null); // apps that are being showed
	const [visibleAppCount, setVisibleAppCount] = useState(null); // number of apps to show
	const [showSeeAllButton, setShowSeeAllButton] = useState(false); // if apps overflow, show button

	const [currentReport, setCurrentReport] = useState(null);

	const notificationTypes = {
		0: <IconControl className="alert" iconKey={"alert"} />,
		2: <IconControl className="important" iconKey={"error"} />,
	};

	const [reportLoading, setReportLoading] = useState(true);

	useEffect(() => {
		profileMgr.RunOperation({ operationName: "ProfileLanding" }).then((r) => {
			if (r.Success) {
				setVisibleApps(r.Items.first().ExternalApps);
				let favReports = r.Items.first().FavoriteReports;
				setReports(favReports);
				setReportLoading(false);
			}
		});

		if (!reportMgr.uiMetadata) {
			reportMgr.GetMetadata({ appId: coreContext.Profile.AppId });
		}

		const updateVisibleApps = () => {
			if (appListRef.current) {
				const cardElement = appListRef.current.querySelector(".app-card");
				if (cardElement) {
					const cardWidth = cardElement.clientWidth + 14;

					const seventyFive = pageRef.current.offsetWidth * 0.7;
					const cardsPerRow = Math.floor(seventyFive / cardWidth);
					const visibleCount = cardsPerRow * 2 < 10 ? 10 : cardsPerRow * 2;
					setVisibleAppCount(visibleCount);

					if (visibleCount !== visibleCountRef.current) {
						visibleCountRef.current = visibleCount;
						setVisibleApps((prevApps) => {
							return prevApps?.slice(0, visibleCount);
						});

						const newDivWidth = cardsPerRow * cardWidth + 14;
						setDivWidth(newDivWidth + "px");
					}
				}
			}
		};

		updateVisibleApps();
		window.addEventListener("resize", updateVisibleApps);

		return () => {
			window.removeEventListener("resize", updateVisibleApps);
		};
	}, []);

	useEffect(() => {
		const updateCardWidth = () => {
			if (carouselRef.current) {
				const firstCard = carouselRef.current.querySelector(".table-card");
				cardWidth.current = firstCard ? firstCard.clientWidth + 14 : 0;
				visibleCards.current = Math.floor(carouselRef.current.clientWidth / cardWidth.current);

				updateButtonStates(currentIndex);
			}
		};

		updateCardWidth();

		window.addEventListener("resize", updateCardWidth);
		return () => window.removeEventListener("resize", updateCardWidth);
	}, [currentIndex, reports]);

	useEffect(() => {
		const adjustHeight = () => {
			if (appRef.current) {
				setNotifHeight(`${appRef.current.offsetHeight}px`);
			}
		};

		adjustHeight();
		window.addEventListener("resize", adjustHeight);

		return () => {
			window.removeEventListener("resize", adjustHeight);
		};
	}, [appRef?.current?.offsetHeight]);

	useEffect(() => {
		if (isAuthorized) {
			let notifications;
			if (coreState?.get("Notification")) {
				notifications = coreState?.get("Notification")?.filteredData.reverse();
			} else {
				notificationMgr
					.Query({
						model: `x => x.IsActive == true && x.IsDeleted == false && x.ProfileId == profileId && x.NotificationType == "Alert"`,
						params: { profileId: coreContext?.Profile?._id },
					})
					.then((r) => {
						if (r?.Success) {
							if (notifications) {
								const updatedItems = r.Items.reduce((un, x) => {
									if (!notifications?.find((y) => y.UniqueId === x.UniqueId)) {
										un.push(x);
									}
									return un;
								}, []);
								if (updatedItems.length > 0) {
									let orderedItems = _.orderBy([...updatedItems, ...notifications], ["CreatedDate"], ["desc"]);
									setNotifications(orderedItems);
									setAllNotifs(orderedItems);
								}
							} else {
								let orderedItems = _.orderBy(r.Items, ["CreatedDate"], ["desc"]);
								setNotifications(orderedItems);
								setAllNotifs(orderedItems);
							}
						}
					});
			}
			setNotifications(notifications);
			setAllNotifs(notifications);
		}
	}, [isAuthorized]);

	useEffect(() => {
		const filteredNotifs = filterTheNotifications();
		setAllNotifs(filteredNotifs);
	}, [searchNotification]);

	const filterTheNotifications = () => {
		return notifications?.filter((notif) => {
			const matchSearch =
				searchNotification.trim() === "" ||
				notif.Title?.toLowerCase().includes(searchNotification.toLowerCase()) ||
				notif.Message?.toLowerCase().includes(searchNotification.toLowerCase());
			return matchSearch;
		});
	};

	useEffect(() => {
		setShowSeeAllButton(visibleApps?.length >= visibleAppCount || visibleApps?.length > 10);
	}, [visibleApps, visibleAppCount]);

	const updateButtonStates = (newIndex) => {
		setIsAtStart(newIndex === 0);
		setIsAtEnd(newIndex + visibleCards.current >= reports?.length);
	};

	const handleNext = () => {
		const newIndex = currentIndex + visibleCards.current;
		setCurrentIndex(newIndex);
		carouselRef.current.scrollLeft += cardWidth.current * visibleCards.current;
		updateButtonStates(newIndex);
	};

	const handlePrev = () => {
		const newIndex = Math.max(currentIndex - visibleCards.current, 0);
		setCurrentIndex(newIndex);
		carouselRef.current.scrollLeft -= cardWidth.current * visibleCards.current;
		updateButtonStates(newIndex);
	};

	const handleOnEdit = (row) => {
		setCurrentReport(row);
	};

	if (isAuthorized) {
		return (
			<>
				<DialogControl
					className={"report-dialog"}
					openDialog={currentReport}
					onX={() => setCurrentReport(null)}
					hideButtonRow={true}
					canFullScreen={true}
					canOpenNewWindow={true}
					shareLink={`${window.location.origin}/reports/${currentReport?._id}`}
					breakoutLink={`${window.location.origin}/reports/${currentReport?._id}`}
					title={currentReport?.Name}
					subTitle={currentReport?.Description}
				>
					<ReportingIFrame reportId={currentReport?._id} />
				</DialogControl>
				{openApps && <Apps setOpenApps={setOpenApps} appList={visibleApps} />}
				<div className="landing-container" ref={pageRef}>
					<div className={"landing-header"}>
						<div className={"landing-img" + (coreContext?.ProfileEncodedImg ? "" : " no-img")}>
							{coreContext.ProfileEncodedImg ? (
								<img src={coreContext.ProfileEncodedImg} />
							) : (
								<div>{coreContext?.Profile?.FirstName?.charAt(0).toUpperCase() + coreContext?.Profile?.LastName?.charAt(0).toUpperCase()}</div>
							)}
						</div>
						<div className="landing-title">{`Welcome, ${coreContext.Profile.FirstName} ${coreContext.Profile.LastName}!`}</div>
					</div>

					<div className="landing-second">
						<div className="landing-apps bubble" ref={appRef}>
							<h3>Apps</h3>
							<div className="app-list" ref={appListRef} style={{ width: divWidth }}>
								{visibleApps
									? visibleApps.map((app, appIndex) => <AppCard key={appIndex} src={app.ImageUrl} appName={app.Name} disabled={!app.IsEnabled} url={app.URL} />)
									: Array.from({ length: 10 }, (_, i) => (
										<LoadingSkeleton key={i} className="app-card" width={"6rem"} height={"6rem"} borderRadius={".5rem"} />
									))}
							</div>
							{showSeeAllButton && (
								<div className="btn-more" onClick={() => setOpenApps(true)}>
									<p>See all apps</p>
									<IconControl iconKey={"arrowright"} />
								</div>
							)}
						</div>
						{canView("Notification") && (
							<div className="landing-notifications bubble" style={{ height: notifHeight }}>
								<div className="landing-notifications-header">
									<h3>Announcements</h3>
									<SearchInput className="notifications-search-wrapper" value={searchNotification} onChange={setSearchNotification} />
								</div>
								<NotificationList
									allNotifs={allNotifs}
									setAllNotifs={setAllNotifs}
									unreadNotifs={[]} // Doesn't do anything because the only tab being displayed is the All tab
									setUnreadNotifs={() => { }} // Doesn't do anything because the only tab being displayed is the All tab
									activeTab={"All"}
									notificationTypes={notificationTypes}
									setNotifications={setNotifications}
								/>
							</div>
						)}
					</div>
					<div className="landing-reports bubble">
						<div className="landing-reports-header">
							<h3>Favorite Reports</h3>
							<div className="carousel-arrows">
								<IconControl iconKey={"angleleft"} className={isAtStart || !reports ? " disabled" : ""} onClick={handlePrev} />
								<IconControl iconKey={"angleright"} className={isAtEnd || !reports ? " disabled" : ""} onClick={handleNext} />
							</div>
						</div>
						<div className="reports-list carousel" ref={carouselRef}>
							{!reportLoading ? (
								reportMgr?.uiMetadata && reports?.length > 0 ? (
									reports?.map((d, i) => {
										return (
											<TableCards cardData={d} key={i} columns={reportMgr?.uiMetadata?.first().Fields} onEdit={handleOnEdit} />
										);
									})
								) : (
									<div style={{ height: "var(--cardHeight)" }}>No reports favorited.</div>
								)
							) : (
								Array.from({ length: 10 }, (_, i) => <LoadingSkeleton key={i} className="table-card" />)
							)}
						</div>
						<div className="btn-more" onClick={() => coreNavigate("/reports/")}>
							<p>See all reports</p>
							<IconControl iconKey={"arrowright"} />
						</div>
					</div>
				</div>
			</>
		);
	} else coreNavigate("/error/notfound/");
};

export default Landing;
