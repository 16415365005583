import React, { createContext, useState, useEffect, useContext } from 'react';
import { useCoreStateContext, useAuthContext } from 'spark-core-dx/contexts';
import { useParams } from 'react-router-dom';
import { ItemServiceFactory } from '../../services/ItemServiceFactory'
import { useGenericManager } from 'spark-core-dx/hooks'

export const ReportsDomainContext = createContext();

export function ReportsDomainContextProvider({ children }) {
    const coreState = useCoreStateContext();
    const { isAuthorized } = useAuthContext();
    const [loadingState, setLoadingState] = useState(true)
    const itemService = new ItemServiceFactory()
    const reportMgr = useGenericManager(itemService.ItemServiceEnum.Report, itemService)
    const [reports, setReports] = useState(null)
    const { id } = useParams();

    useEffect(() => {
        return () => {
            coreState.clear()
        }
    }, [])

    useEffect(() => {
        if (isAuthorized) {
            reportMgr.search({ searchModel: 'x => x.IsActive == true && x.IsDeleted == false' }).then(r => {
                setReports(r)
                setLoadingState(false)
            })
        }
    }, [isAuthorized])

    return (
        <ReportsDomainContext.Provider value={{ reports, id, loadingState, reportMgr }} >
            {children}
        </ReportsDomainContext.Provider>

    );
}

export const useReportsDomainContext = () => useContext(ReportsDomainContext);