import React, { useEffect, useState } from 'react'
import { useGenericManager } from 'spark-core-dx/hooks'
import { Loading, DialogControl, IconControl } from 'spark-core-dx/components'
import { ItemServiceFactory } from '../../../services/ItemServiceFactory'
import { useParams } from 'react-router-dom';
import { useWizardDomainContext } from '../WizardDomainProvider';
import SystemOfRecords from './components/SystemOfRecords';
import LegacySystems from './components/LegacySystems';
import Application from './components/Application';
import WarehouseAndReporting from './components/WarehouseAndReporting';

const SystemApplicationSetup = () => {
    const { } = useWizardDomainContext();
    const { cid } = useParams();

    const itemService = new ItemServiceFactory()
    const externalAppMgr = useGenericManager(itemService.ItemServiceEnum.ExternalApp, itemService);
    const franchiseeExternalAppSettingMgr = useGenericManager(itemService.ItemServiceEnum.FranchiseeExternalAppSetting, itemService);
    const configMgr = useGenericManager(itemService.ItemServiceEnum.Config, itemService);

    const [franchiseeOptions, setFranchiseeOptions] = useState([])
    const [francEASItems, setFrancEASItems] = useState([])

    const [applicationList, setApplicationList] = useState({})
    const [currentTab, setCurrentTab] = useState(1);
    const [config, setConfig] = useState(null)

    const [isLoading, setIsLoading] = useState(true)
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
    const [changesDialog, setChangesDialog] = useState(false)
    const [tab, setTab] = useState(1)

    useEffect(() => {
        grabData();
    }, [])

    const grabData = async () => {
        externalAppMgr.search({}).then(r => {
            setFranchiseeOptions(r)
        })
        franchiseeExternalAppSettingMgr.search({ searchModel: `x => x.ClientId == cid && x.IsActive == true && x.IsDeleted == false`, params: { cid: cid } }).then(r => {
            setFrancEASItems(r)
            setIsLoading(false)
        })
        configMgr.Query({ model: "x => x.IsActive == true && x.IsDeleted == false && x.ClientId == cid", params: { cid: cid } }).then(r => {
            if (r.Success) {
                const resultedConfig = r.Items.first();
                setConfig(resultedConfig)
            }
        })
    }

    useEffect(() => {
        const mapApplicationsByCategory = () => {
            const categoryMap = {};

            francEASItems.forEach((object) => {
                const appList = franchiseeOptions.find(app => app.UniqueId === object.ExternalAppId);
                if (appList && appList.CategoryName) {
                    if (!categoryMap[appList.CategoryName]) {
                        categoryMap[appList.CategoryName] = [];
                    }
                    categoryMap[appList.CategoryName][appList.Name] = object;
                }
            });

            return categoryMap;
        }
        const appList = mapApplicationsByCategory()
        setApplicationList(appList)
    }, [francEASItems, franchiseeOptions])

    const handleTabChange = (selectedTab) => {
        if (currentTab !== selectedTab) {
            setTab(selectedTab)
            if (hasUnsavedChanges) {
                setChangesDialog(true)
            } else {
                setCurrentTab(selectedTab)
            }
        }
    }

    const renderSteps = () => {
        switch (currentTab) {
            case 1: {
                return <SystemOfRecords setChanges={setHasUnsavedChanges} config={config} setConfig={setConfig} applicationList={applicationList['System of Records']} setCurrentTab={setCurrentTab} />
            }
            case 2: {
                return <Application setChanges={setHasUnsavedChanges} applicationList={applicationList['Application']} setCurrentTab={setCurrentTab} />;
            }
            case 3: {
                return <WarehouseAndReporting setChanges={setHasUnsavedChanges} applicationList={applicationList['Warehouse and Reporting']} setCurrentTab={setCurrentTab} />;
            }
            case 4: {
                return <LegacySystems setChanges={setHasUnsavedChanges} applicationList={applicationList['Legacy Systems']} setCurrentTab={setCurrentTab} />
            }
        }
    }

    return (
        <>
            <DialogControl
                className={'with-icon wizard-dialog'}
                title={[<IconControl iconKey={'alert'} />, "Unsaved Changes"]}
                openDialog={changesDialog}
                onOk={() => { setCurrentTab(tab); setChangesDialog(false); setHasUnsavedChanges(false) }}
                cancelText={'Return'}
                onCancel={() => setChangesDialog(false)}
                okText={'Continue'}>
                <div className="dialog-text">
                    You are about to leave this page without saving.
                    Please click "Return" to go back and save your changes or click "Continue" to continue without saving.
                </div>
            </DialogControl>
            <div className={`wizard-tabs${isLoading ? ' disabled' : ''}`}>
                {["System of Records", "Application", "Warehouse and Reporting", "Legacy Systems"].map((tab, index) => {
                    return (
                        <div key={index}
                            className={`wizard-tab` + ((currentTab === index + 1) ? ' active-tab' : '')}
                            onClick={() => {
                                handleTabChange(index + 1);
                            }}>
                            {tab}
                        </div>
                    )
                })}
            </div>
            {!isLoading ? renderSteps() : <Loading type={'spinner-2'} />}
        </>
    )
}

export default SystemApplicationSetup