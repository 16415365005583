import React, { useEffect, useState } from 'react';
import { CheckboxControl, Loading } from 'spark-core-dx/components';
import './listOfUsers.scss'
const ListOfUsers = ({ onChange, disable, datajson, value, onSelectAll }) => {
    const [searchText, setSearchText] = useState("");
    const onSelect = (selection) => {
        let copiedSelected = [...value]

        if (copiedSelected.includes(selection)) {
            copiedSelected = copiedSelected.filter(x => x !== selection)
        } else
            copiedSelected = [...copiedSelected, selection]
        onChange(copiedSelected)
    }

    const renderImage = (user) => {

        if (user.Image === undefined) {
            return <Loading type={'spinner-2'} />
        } else if (user.Image === "") {
            return <div className="user-pfp-placeholder">{user.Name?.charAt(0)}</div>
        } else {
            return <img src={user.Image} className="user-pfp" />
        }
    }

    const filteredJson = searchText
        ? datajson?.filter(x => x.Name.toLowerCase().includes(searchText.toLowerCase())).map(x => x.Value)
        : datajson.map(x => x.Value);

    return (
        <div className="user-list">
            <div className="user-list-container">
                <div className="user-list-search">
                    <CheckboxControl value={
                        filteredJson.length > 0
                            ? filteredJson.every(x => value.includes(x))
                                ? true
                                : filteredJson.some(x => value.includes(x))
                                    ? 'indeterminate'
                                    : false
                            : false
                    }
                        onChange={() => {
                            onSelectAll(
                                value.length > 0 && value.every(x => filteredJson.includes(x))
                                    ? []
                                    : filteredJson
                            );
                        }}
                    />
                    <input type="text" placeholder='Search..' value={searchText} onChange={(e) => {
                        setSearchText(e.target.value)
                    }} />
                </div>

                {!disable ? datajson.filter(x => !searchText ? true : x.Name.toLowerCase().includes(searchText.toLowerCase()))?.map((x, i) => {
                    return <div className="user-list-row" key={i}
                        onClick={() => {
                            onSelect(x.Value)
                        }}>
                        <div onClick={(e) => e.stopPropagation()}>
                            <CheckboxControl
                                value={value.includes(x.Value)}
                                onChange={() => {
                                    onSelect(x.Value)
                                }} />
                        </div>
                        {
                            renderImage(x)
                        }
                        <div className="user-info">
                            <div className="name" title={x.Name}>{x.Name}</div>
                            <div className="email" title={x.Email}>{x.Email}</div>
                        </div>
                    </div>
                }) :
                    "Please choose a group"
                }




            </div>
        </div>
    );
};

export default ListOfUsers;