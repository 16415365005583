import React, { useEffect, useState } from "react";
import "../Reports.scss";
import { DialogControl, TableControl } from "spark-core-dx/components";
import { ReportingIFrame } from "./ReportingIFrame";
import { useReportsDomainContext } from "../ReportsDomainProvider";
import { useGenericManager } from "spark-core-dx/hooks";
import { useCoreContext } from "spark-core-dx/contexts";
import { ItemServiceFactory } from "../../../services/ItemServiceFactory";

const Reports = ({ }) => {
    const { reports, loadingState, reportMgr } = useReportsDomainContext();
    const coreContext = useCoreContext();
    const itemService = new ItemServiceFactory();
    const appProfilePreferencesMgr = useGenericManager(itemService.ItemServiceEnum.AppProfilePreferences, itemService);
    const [appProfPref, setAppProfPref] = useState({
        ClientId: coreContext?.Profile.ClientId,
        AppId: coreContext?.Profile.AppId,
        OwnerProfileId: coreContext?.Profile._id,
    });
    const [favoriteReports, setFavoriteReports] = useState([]);
    const [currentReport, setCurrentReport] = useState(null);

    useEffect(() => {
        appProfilePreferencesMgr
            .search({
                searchModel: `x => x.IsActive == true && x.IsDeleted == false && x.OwnerProfileId == profileId`,
                params: { profileId: coreContext?.Profile._id },
            })
            .then((r) => {
                let preference = r?.first();
                setAppProfPref(preference);
                if (preference?.CustomFields?.FavoriteReports) {
                    setFavoriteReports(JSON.parse(preference?.CustomFields?.FavoriteReports));
                }
            });
    }, []);

    const onServerSideQuery = async () => {
        const searchModel = reportMgr?.State["serverSideQuery"];
        await reportMgr.search({
            searchModel: searchModel.trim() === "x =>" ? null : reportMgr?.State["serverSideQuery"],
            forceUpdate: true,
        });
    };

    const handleOnEdit = (row) => {
        setCurrentReport(row);
    };

    const handleOnFavorite = (id) => {
        const copyOfFavoriteReports = favoriteReports.includes(id) ? favoriteReports.filter((x) => x !== id) : [...favoriteReports, id];

        const updatedAppProfPref = {
            ...appProfPref,
            CustomFields: {
                ...appProfPref?.CustomFields,
                FavoriteReports: JSON.stringify(copyOfFavoriteReports),
            },
        };

        setFavoriteReports(copyOfFavoriteReports);
        appProfilePreferencesMgr.saveItem(updatedAppProfPref).then((r) => {
            if (r.Success) {
                setAppProfPref(r.Items.first())
            }
        });
    };

    return (
        <>
            {reportMgr?.uiMetadata && (
                <TableControl
                    data={reports}
                    enableMultiSelect={false}
                    defaultNumRows={50}
                    onServerSideQuery={onServerSideQuery}
                    metadata={[{ ...reportMgr?.uiMetadata.first(), GridType: 2 }]}
                    loadingState={loadingState}
                    filters={reportMgr?.filters}
                    mgr={reportMgr}
                    enableSidePanelEdit={true}
                    onEdit={handleOnEdit}
                    onFavorite={handleOnFavorite}
                    favoriteList={favoriteReports}
                />
            )}
            <DialogControl
                className={"report-dialog"}
                openDialog={currentReport}
                onX={() => setCurrentReport(null)}
                hideButtonRow={true}
                canFullScreen={true}
                canOpenNewWindow={true}
                shareLink={`${window.location.origin}/reports/${currentReport?._id}`}
                breakoutLink={`${window.location.origin}/reports/${currentReport?._id}`}
                title={currentReport?.Name}
                subTitle={currentReport?.Description}
            >
                <ReportingIFrame reportId={currentReport?._id} />
            </DialogControl>
        </>
    );
};

export default Reports;
