import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { ButtonControl, FieldControl, IconControl, Loading, DialogControl, CheckboxControl } from 'spark-core-dx/components';
import { useWizardDomainContext } from '../../WizardDomainProvider';
import { useValidationErrors, useGenericManager } from 'spark-core-dx/hooks'
import { ItemServiceFactory } from '../../../../services/ItemServiceFactory';
import WizardFooter from './WizardFooter';

const Locations = ({ }) => {
  const { handleProgress, currentStep, setCurrentStep, updateOnboardingProcess } = useWizardDomainContext();
  const { hasAndShowValidationErrors, getValidationErrors } = useValidationErrors();

  const itemService = new ItemServiceFactory()
  const franchiseeLocationMgr = useGenericManager(itemService.ItemServiceEnum.FranchiseeLocation, itemService);
  const clientMgr = useGenericManager(itemService.ItemServiceEnum.Client, itemService);

  const { cid } = useParams();

  const [errorLoc, setErrorLoc] = useState([])
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState(null)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    franchiseeLocationMgr.search({ searchModel: `x => x.ClientId == cid && x.IsActive == true && x.IsDeleted == false`, params: { cid: cid } }).then(async (r) => {
      if (r.length > 0) {
        const locations = handleLocations(r)
        setCompLocations(locations);
      } else {
        await clientMgr.search({ searchModel: `x => x.ClientId == cid && x.IsActive == true && x.IsDeleted == false`, params: { cid: cid } }).then(r => {
          const primLoc = transformClientItemToLocation(r.first())
          setCompLocations(primLoc)
        })
      }
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (errorLoc.length > 0) {
      setCompLocations(prevLocations =>
        prevLocations.map((location, index) => {
          if (errorLoc.includes(index.toString())) {
            return {
              ...location,
              open: true,
            };
          }
          return location;
        })
      );
    }
  }, [errorLoc]);

  const handleOnCustomValidate = () => {
    const locationNames = compLocations.map(location => location.item.LocationName);
    const uniqueLocationNames = new Set(locationNames);
    if (uniqueLocationNames.size === locationNames.length) {
      return true
    } else {
      return "Duplicate Location name found."
    }
  }

  const handleLocations = (locations) => {
    // Transforms from FranLocItem to Suitable for Locations Page.
    const transformedLocations = locations.map((location, index) => (
      {
        open: (index + 1 === locations.length),
        ein: location?.EIN ? true : false,
        item: {
          LocationName: location?.LocationName,
          PrimaryPhone: location?.PrimaryPhone,
          SecondaryPhone: location?.SecondaryPhone,
          Address: {
            StreetOne: location?.StreetOne,
            StreetTwo: location?.StreetTwo,
            State: location?.State,
            Country: location?.Country,
            County: location?.County,
            City: location?.City,
            ZipCode: location?.ZipCode
          },
          ClientId: location?.ClientId,
          EIN: location?.EIN,
          IsPrimary: location?.IsPrimary,
          UniqueId: location?.UniqueId
        }
      }));

    const sortedLocations = transformedLocations.sort((a, b) => {
      return (b.item.IsPrimary === true ? 1 : 0) - (a.item.IsPrimary === true ? 1 : 0);
    });

    return sortedLocations;
  }

  const transformClientItemToLocation = (clientData) => {
    const { PrimaryPhone, SecondaryPhone, PrimaryAddress, ClientId } = clientData;

    const transformedAddress = {
      StreetOne: PrimaryAddress?.StreetOne,
      StreetTwo: PrimaryAddress?.StreetTwo,
      City: PrimaryAddress?.City,
      State: PrimaryAddress?.State,
      Region: PrimaryAddress?.Region,
      County: PrimaryAddress?.County,
      Country: PrimaryAddress?.Country,
      ZipCode: PrimaryAddress?.ZipCode
    };

    const locItem = {
      open: true,
      ein: false,
      item: {
        LocationName: '',
        PrimaryPhone: PrimaryPhone,
        SecondaryPhone: SecondaryPhone,
        Address: transformedAddress,
        ClientId: ClientId,
        EIN: '',
        IsPrimary: true,
        UniqueId: undefined
      }
    };

    return ([locItem])
  }

  const [compLocations, setCompLocations] = useState([{
    open: true,
    ein: false,
    item: {
      LocationName: '',
      PrimaryPhone: '',
      SecondaryPhone: '',
      Address: {},
      ClientId: '',
      EIN: '',
      IsPrimary: '',
      UniqueId: undefined
    }
  }])

  const FieldTypeLookup = {
    LocationName: {
      key: 'location-name',
      fieldType: 1,
      fieldName: 'LocationName',
      displayName: 'Location Name',
      req: true,
    },
    EIN: {
      key: 'ein',
      req: false,
    },
    PrimaryPhone: {
      key: 'primary-phone',
      fieldType: 1,
      fieldName: 'PrimaryPhone',
      displayName: 'Primary Phone Number',
      req: true,
      valType: 13
    },
    SecondaryPhone: {
      key: 'secondary-phone',
      fieldType: 1,
      fieldName: 'SecondaryPhone',
      displayName: 'Secondary Phone Number',
      req: false,
      valType: 13
    },
    Address: {
      key: 'location-address',
      fieldType: 20,
      fieldName: 'Address',
      displayName: 'Location Address',
      req: true,
    },
  }

  const addLocation = () => {
    const newLocations = [...compLocations]
    newLocations[newLocations.length - 1].open = false;

    newLocations.push({
      open: true,
      ein: false,
      item: {
        LocationName: '',
        PrimaryPhone: '',
        SecondaryPhone: '',
        Address: {},
        ClientId: '',
        EIN: '',
        IsPrimary: '',
        UniqueId: undefined
      }
    });

    setCompLocations(newLocations);
  };

  const updateProgress = (locations) => {
    let filledFields = 0;
    let totalFields = 0;

    locations.forEach(location => {
      if (location.ein) {
        totalFields++;
        if (location.item.EIN) filledFields++;
      }

      Object.entries(location.item).forEach(([key, value]) => {
        if (key === 'ClientId' || key === 'IsPrimary') return;
        const isRequired = FieldTypeLookup[key]?.req;

        if (key === 'Address') {
          const hasAddressValue = Object.values(location.item[key]).some(fieldValue => fieldValue && (typeof fieldValue === 'string' && fieldValue.trim().length > 0));
          if (isRequired && hasAddressValue) filledFields++;
        } else {
          if (isRequired && value) filledFields++;
        }
        if (isRequired) totalFields++;
      });
    });
    return { filledFields, totalFields }
    // handleProgress(filledFields, totalFields, "page3")
  };

  const handleEINCheckbox = (index) => {
    const newLocations = [...compLocations];

    newLocations[index] = {
      ...newLocations[index],
      ein: !newLocations[index].ein
    };

    setCompLocations(newLocations);
  }

  const handleValueFields = (index, v, key) => {
    const newLocations = [...compLocations];

    newLocations[index] = {
      ...newLocations[index],
      item: {
        ...newLocations[index].item,
        [key]: v,
      }
    };

    setCompLocations(newLocations);
  }

  const editLocation = (index) => {
    const newLocations = [...compLocations]
    newLocations[index].open = true;
    setCompLocations(newLocations);
  }

  const saveLocation = (index) => {
    const newLocations = [...compLocations]
    newLocations[index].open = false;
    setCompLocations(newLocations);
  }

  const createFranLocItem = (locations) => {
    const item = locations.map((location, index) => {
      const { Address, ...otherFields } = location.item;

      const flattenedItem = {
        ...otherFields,
        ...Address,
        ClientId: cid,
        IsPrimary: index === 0 ? true : false
      };

      return flattenedItem;
    });
    return item;
  }

  const deleteLocation = async (index) => {
    const newLocations = [...compLocations]

    if (compLocations[index].item.UniqueId) {
      const locItemToDelete = createFranLocItem([compLocations[index]]).first()

      await franchiseeLocationMgr.deleteItem(locItemToDelete).then(r => {
        newLocations.splice(index, 1);
        setDeleteDialog(false);

        setCompLocations(newLocations);
      })

    } else {
      newLocations.splice(index, 1);
      setDeleteDialog(false);

      setCompLocations(newLocations);
    }
  }

  const handleSave = async (isContinue) => {
    if (!hasAndShowValidationErrors('locations')) {
      const franLocItem = createFranLocItem(compLocations)

      try {
        for (const item of franLocItem) {
          await franchiseeLocationMgr.saveItem(item);
        }

        const { filledFields, totalFields } = updateProgress(compLocations)

        if (isContinue) {
          setCurrentStep(currentStep + 1)
          await handleProgress(filledFields, totalFields, 'page3', updatedPageValues => {
            updateOnboardingProcess(currentStep + 1, updatedPageValues);
          });
          // await updateOnboardingProcess(currentStep + 1)
        } else {
          await handleProgress(filledFields, totalFields, 'page3', updatedPageValues => {
            updateOnboardingProcess(currentStep, updatedPageValues);
          });
        }
      } catch (error) {
        return false;
      }
    }
    else {
      const listOfErrors = getValidationErrors('locations')
      const errorList = Object.keys(listOfErrors).filter(x => x !== "__updatedDate").map(y => { return y.split('-')[0] })
      setErrorLoc([...new Set(errorList)])
      return false;
    }
  }

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1)
  }

  return (<>
    <div className="wizard-content">
      {isLoading ? <Loading type={'spinner-2'} /> :
        <>
          <DialogControl
            className={"wizard-dialog"}
            openDialog={deleteDialog}
            title={`Confirm Deletion`}
            onCancel={() => setDeleteDialog(false)}
            onOk={() => deleteLocation(deleteIndex)}
            okText={'Confirm'}>
            <div>
              <p>Please confirm the deletion of this location.</p>
              {
                compLocations[deleteIndex]?.item?.UniqueId &&
                <div style={{ marginTop: '1rem' }}>
                  <p>{compLocations[deleteIndex]?.item?.LocationName}</p>
                  <p>{`${compLocations[deleteIndex]?.item?.Address.StreetOne} ${compLocations[deleteIndex]?.item?.Address?.StreetTwo ? compLocations[deleteIndex]?.item?.Address?.StreetTwo : ''}`}</p>
                  <p>{`(${compLocations[deleteIndex]?.item?.Address.ZipCode}) ${compLocations[deleteIndex]?.item?.Address.City}, ${compLocations[deleteIndex]?.item?.Address.State}`}</p>
                  <p>{`${compLocations[deleteIndex]?.item?.Address.Country}`}</p>
                </div>
              }
            </div>
          </DialogControl>
          {compLocations.map((location, index) => {
            const { item } = location;

            const hasValue =
              item.LocationName?.trim().length > 0 ||
              item.PrimaryPhone?.trim().length > 0 ||
              item.SecondaryPhone?.trim().length > 0 ||
              (item.Address && Object.keys(item.Address).length > 0) ||
              item.EIN?.trim().length > 0;

            const showIcons = compLocations.length > 1 || hasValue || compLocations.length === 1 && !location.open;
            const marginRight = showIcons ? '' : '0';
            return (
              <div className="location-row" key={`location-${index}`}>
                <div className={`location-name${!location.open ? ' closed' : ''}`}
                  style={{ marginRight: marginRight }}>
                  <FieldControl
                    key={FieldTypeLookup['LocationName'].key}
                    groupId={'locations'}
                    fieldMetadata={{
                      FieldType: FieldTypeLookup['LocationName'].fieldType,
                      FieldName: `${index}-${FieldTypeLookup['LocationName'].fieldName}`,
                      DisplayName: location.open ? `${index + 1}. ${FieldTypeLookup['LocationName'].displayName}` : `${index + 1}.`,
                      IsRequired: FieldTypeLookup['LocationName'].req,
                    }}
                    value={compLocations[index].item[FieldTypeLookup['LocationName'].fieldName]}
                    onChange={v => handleValueFields(index, v, 'LocationName')}
                    onCustomValidate={handleOnCustomValidate}
                    validationMessage={`Location name is required.`}
                  />
                  <div className="location-icons">
                    {showIcons ?
                      <>
                        {location.open ? <IconControl iconKey={'save'} onClick={() => saveLocation(index)} /> : ""}
                        {location.open ? "" : <IconControl iconKey={'edit'} onClick={() => editLocation(index)} />}
                        {compLocations.length > 1
                          ? <IconControl iconKey={'delete'} onClick={() => {
                            setDeleteIndex(index)
                            setDeleteDialog(true)
                          }} />
                          : <IconControl iconKey={'delete'} className={'disabled'} />}
                      </>
                      : ""
                    }

                  </div>
                </div>
                {
                  <div className="location-fields"
                    style={{
                      maxHeight: location.open
                        ? '455px' : '0px'
                    }}>
                    <div className='grouped-fields'>
                      <FieldControl
                        key={FieldTypeLookup['PrimaryPhone'].key}
                        groupId={'locations'}
                        fieldMetadata={{
                          FieldType: FieldTypeLookup['PrimaryPhone'].fieldType,
                          FieldName: `${index}-${FieldTypeLookup['PrimaryPhone'].fieldName}`,
                          DisplayName: FieldTypeLookup['PrimaryPhone'].displayName,
                          IsRequired: FieldTypeLookup['PrimaryPhone'].req,
                          ValidationType: FieldTypeLookup['PrimaryPhone'].valType ?? null,
                        }}
                        value={compLocations[index]?.item[FieldTypeLookup['PrimaryPhone'].fieldName] ?? ''}
                        onChange={v => handleValueFields(index, v, 'PrimaryPhone')}
                      />
                      <FieldControl
                        key={FieldTypeLookup['SecondaryPhone'].key}
                        groupId={'locations'}
                        fieldMetadata={{
                          FieldType: FieldTypeLookup['SecondaryPhone'].fieldType,
                          FieldName: `${index}-${FieldTypeLookup['SecondaryPhone'].fieldName}`,
                          DisplayName: FieldTypeLookup['SecondaryPhone'].displayName,
                          IsRequired: FieldTypeLookup['SecondaryPhone'].req,
                          ValidationType: FieldTypeLookup['SecondaryPhone'].valType ?? null
                        }}
                        value={compLocations[index]?.item[FieldTypeLookup['SecondaryPhone'].fieldName] ?? ''}
                        onChange={v => handleValueFields(index, v, 'SecondaryPhone')}
                      />
                    </div>

                    <FieldControl
                      key={FieldTypeLookup['Address'].key}
                      groupId={'locations'}
                      fieldMetadata={{
                        FieldType: FieldTypeLookup['Address'].fieldType,
                        FieldName: `${index}-${FieldTypeLookup['Address'].fieldName}`,
                        DisplayName: FieldTypeLookup['Address'].displayName,
                        IsRequired: FieldTypeLookup['Address'].req,
                        ValidationType: FieldTypeLookup['Address'].valType ?? null
                      }}
                      value={compLocations[index]?.item[FieldTypeLookup['Address'].fieldName] ?? ''}
                      onChange={v => handleValueFields(index, v, 'Address')}
                    />
                    <div className='ein-container'>
                      <div className={`checkbox-container`} onClick={() => handleEINCheckbox(index)}>
                        <div onClick={(e) => e.stopPropagation()}>
                          <CheckboxControl
                            value={location['ein']}
                            onChange={() => handleEINCheckbox(index)}
                          />
                        </div>
                        <span>Different EIN</span>
                      </div>
                      {
                        location['ein'] ?
                          <FieldControl
                            key={FieldTypeLookup['EIN'].key}
                            groupId={'locations'}
                            fieldMetadata={{
                              FieldType: 1,
                              FieldName: `${index}-EIN`,
                              IsRequired: true,
                              ValidationType: FieldTypeLookup['EIN'].valType ?? null,
                            }}
                            value={location?.item['EIN'] ?? ''}
                            onChange={v => handleValueFields(index, v, 'EIN')}
                          />
                          : ''
                      }
                    </div>
                  </div>
                }
              </div>
            )
          })}
          <div className="location-add">
            <ButtonControl className={'form-footer-btn'} minWidth={'7rem'} value={'+ Add another location'} type={'okay'} onClick={addLocation} />
          </div>
        </>
      }
    </div>
    <WizardFooter
      disableAll={isLoading}
      showPrevBtn={true}
      prevBtnOnClick={handlePrevious}
      showSecBtn={true}
      primaryBtnOnClick={async () => await handleSave(true)}
      secBtnOnClick={async () => await handleSave()}
      primaryBtnText={"Save & Continue"}
      secBtnText={"Save"} />
  </>
  )
}

export default Locations