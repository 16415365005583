import React, { useEffect, useState } from 'react'
import { useGenericManager } from 'spark-core-dx/hooks'
import { Loading, DialogControl, IconControl } from 'spark-core-dx/components'
import { ItemServiceFactory } from '../../../services/ItemServiceFactory'
import { useParams } from 'react-router-dom';
import { useWizardDomainContext } from '../WizardDomainProvider';
import Logos from './components/Logos';
import Branding from './components/Branding';
import SystemConfiguration from './components/SystemConfiguration';
import CompanyInformation from './components/CompanyInformation';
import SetupUsers from './SetupUsers';
import { useCoreContext } from 'spark-core-dx/contexts';

const FranchiseeSetup = () => {
  const { cid } = useParams();
  const coreContext = useCoreContext()
  const itemService = new ItemServiceFactory()
  const clientMgr = useGenericManager(itemService.ItemServiceEnum.Client, itemService);
  const themeMgr = useGenericManager(itemService.ItemServiceEnum.Theme, itemService);
  const configMgr = useGenericManager(itemService.ItemServiceEnum.Config, itemService);
  const { setClientItem, clientItem, updateOnboardingProcess, onboardingItem } = useWizardDomainContext();
  const [currentTab, setCurrentTab] = useState((onboardingItem.SystemConfigurationCompleted !== null && onboardingItem.Step === 1) ? 4 : 1)
  const [themeItem, setThemeItem] = useState(null)

  const [isLoading, setIsLoading] = useState(true);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [changesDialog, setChangesDialog] = useState(false)
  const [tab, setTab] = useState(1)

  useEffect(() => {
    grabData().then(() => {
      setIsLoading(false)
    })
  }, [cid, clientItem])

  const grabData = async () => {
    if (cid) {
      themeMgr.search({ searchModel: `x => x.ClientId == cid`, params: { cid: cid } }).then(r => {
        if (r?.first()) {
          setThemeItem(r.first())
        }
      })
    }

    if (!clientMgr.uiMetadata)
      clientMgr.GetMetadata({ appId: coreContext.Profile.AppId })

    if (!themeMgr.uiMetadata)
      themeMgr.GetMetadata({ appId: coreContext.Profile.AppId })
  }

  const renderSteps = () => {
    switch (currentTab) {
      case 1: {
        return <CompanyInformation setCurrentTab={setCurrentTab} setChanges={setHasUnsavedChanges} />
      }
      case 2: {
        return <Logos setCurrentTab={setCurrentTab} themeItem={themeItem} setThemeItem={setThemeItem} setChanges={setHasUnsavedChanges} />
      }
      case 3: {
        return <Branding setCurrentTab={setCurrentTab} setThemeItem={setThemeItem} themeItem={themeItem} setChanges={setHasUnsavedChanges} />
      }
      case 4: {
        return <SystemConfiguration setCurrentTab={setCurrentTab} setChanges={setHasUnsavedChanges} />
      }
    }
  }

  const handleTabChange = (selectedTab) => {
    if (currentTab !== selectedTab) {
      setTab(selectedTab)
      if (hasUnsavedChanges) {
        setChangesDialog(true)
      } else {
        setCurrentTab(selectedTab)
      }
    }
  }

  return (
    <>
      <DialogControl
        className={'with-icon wizard-dialog'}
        title={[<IconControl iconKey={'alert'} />, "Unsaved Changes"]}
        openDialog={changesDialog}
        onOk={() => { setCurrentTab(tab); setChangesDialog(false); setHasUnsavedChanges(false) }}
        cancelText={'Return'}
        onCancel={() => setChangesDialog(false)}
        okText={'Continue'}>
        <div className="dialog-text">
          You are about to leave this page without saving.
          Please click "Return" to go back and save your changes or click "Continue" to continue without saving.
        </div>
      </DialogControl>
      <div className={`wizard-tabs${isLoading ? ' disabled' : ''}`}>
        {["Company Information", "Logos", "Branding", "System Configuration"].map((tab, index) => {
          return (
            <div key={index}
              className={`wizard-tab` + ((currentTab === index + 1) ? ' active-tab' : '') + ((index !== 0 && !cid) ? " disabled" : '')}
              onClick={() => {
                if (cid !== null)
                  handleTabChange(index + 1);
              }}>
              {tab}
            </div>
          )
        })}
      </div>
      {!isLoading ? renderSteps() : <Loading type={'spinner-2'} />}
    </>
  )
}

export default FranchiseeSetup;